/* ===================================
     Start Document.Ready
     ====================================== */
$(document).ready(function() {
  $(".swatch_select").change(function() {    
    var color = $(this).val();    
    $(this)
    //.parents('.product_variant')
    .parents('.featured_detail_content')
    .find('#SingleOptionSelector-' + $(this).attr('name'))    
    .val(color)
    .change();
  });

  /* ===================================
     START Window Height 
     ====================================== */
  var window_height = $(window).height();
  var headeheight = $('header').outerHeight();
  var footeheight = $('footer').height();
  var sub_total = headeheight + footeheight;
  var total_height = window_height - sub_total;
  $('.main_section').css('min-height', total_height);
  /* ===================================
     END Window Height
     ====================================== */

  /* ===================================
     START Registration Page Validation
     ====================================== */
  $(".input_validation").keypress(function(event) {
    var character = String.fromCharCode(event.keyCode);
    return isValid(character);     
  });

  $('.password_validation').keypress(function( e ){
    if(e.which === 32) 
      return false;
  });
  /* ===================================
     END Registration Page Validation
     ====================================== */

  /* ===================================
     START Newsletter Popup
     ====================================== */
  
  if (!$.cookie('panel_cookie') === null) {
  
    $('#newsletter').hide();
    
  }
  $('.new_close').on("click", function () {
    $('#newsletter').hide();
    $.cookie('panel_cookie', 'closed', {expires: 1, path: '/'});
  });

  $('#newsletter').on("click", function (event) {
    $.cookie('panel_cookie', 'closed', {expires: 1, path: '/'});
    
    var $target = $(event.target);    
      if(!$target.parents().is(".news_form") && !$target.is(".news_form")){
     $('#newsletter').hide();
      
    }
  });

      
  /* ===================================
     END Newsletter Popup
     ====================================== */

  $(".narrow-by-list .swatch-list .item:not(.disable)").unbind("click");

  /* ===================================
     START Mega Menu
     ====================================== */
  if ($(window).width() >= 1024) {
    $(".navbar-nav .dropdown").on('mouseover', function (e) {
      $(this).children('.menu_arrow').addClass('open');
      $(this).find('.dropdown-menu').stop(true, true).slideDown(300).css('display', 'flex');
      e.stopPropagation();
    });
    $(".navbar-nav .dropdown-menu").on('mouseleave', function (e) {
      $(this).hide();
      $(this).parent('.dropdown').children('.menu_arrow').removeClass('open');
      e.stopPropagation();
    });
    $(document).on('mouseover', function () {
      $(".navbar-nav .dropdown-menu").hide();
      $('.menu_arrow').removeClass('open');
    });
  } else {
    $(".menu_arrow").on("click", function () {
      $(this).siblings("ul").slideToggle();
      $(this).toggleClass('open');
      $(this).parent(".navbar-nav li ").siblings().children("ul").hide();
      $(this).parents('.navbar-nav li').siblings().children('span').removeClass('open');

      $(this).siblings('ul').children('li').children('ul').hide();
      $(this).siblings('ul').children('li').children('span').removeClass('open');
    });
  }
  $('header .navbar .navbar-nav .nav-item .dropdown-menu li ul li').on("click", function () {
    var img_src = $(this).data('img');
    var data_title = $(this).data('title');
    $(".drop_img").attr("src", img_src);
    $(".drop_title").text(data_title);
  });
  $('header button[data-toggle="collapse"]').on("click", function () {
    $(".navbar-collapse").addClass("show");
    $('body').toggleClass('mobile-nav-shown');
    $(this).toggleClass('open');
  });
  $('.menu_colse,.menu_overlay').on("click", function () {
    $('body').removeClass('mobile-nav-shown');
    $(".navbar-collapse").removeClass("show");
    $('.navbar-toggler').removeClass('open');
    $('li .dropdown-menu').hide();
  });
  /* ===================================
     END Mega Menu
     ====================================== */

  /* ===================================
     START Quantity Box
     ====================================== */  
  if ($(window).width() < 769) {
    $(".qty_number--desktop input").attr("disabled", true);
    $(".qty_number--mobile input").attr("disabled", false);
  } else {
    $(".qty_number--desktop input").attr("disabled", false);
    $(".qty_number--mobile input").attr("disabled", true);
  }
  $(".qty_number").append('<div class="inc button"><span class="tw-flex tw-justify-center tw-items-center"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 5.7037H5.7037V10H4.2963V5.7037H0V4.2963H4.2963V0H5.7037V4.2963H10V5.7037Z" fill="#313131"/></svg></span></div><div class="dec button"><span class="tw-flex tw-justify-center tw-items-center"><svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0.25V1.75H0V0.25H10Z" fill="#313131"/></svg></span></div>');
  $(".button").on("click", function () {
    console.log('click')
    var $button = $(this);
    var oldValue = $button.parent().find("input").val();
    if (oldValue == "") {
      oldValue = 1;
    }
    if ($button.hasClass("inc")) {
      var newVal = parseFloat(oldValue) + 1;
    } else {
      // Don't allow decrementing below zero
      if (oldValue > 1) {
        var newVal = parseFloat(oldValue) - 1;
      } else {
        newVal = 1;
      }
    }
    $button.parent().find("input").val(newVal);
  });
  /* ===================================
     END Quantity Box
     ====================================== */

  /* ===================================
     Start Filter Toggle
     ====================================== */
//   $(document).on("click", ".category_close_icon", function (a) {
//     $(this).parent('.category_list_title').siblings('.layer-filter').slideToggle();
//     $(this).parent('.category_list_title').toggleClass('open-filter');
//   });
  /* ===================================
     End Filter Toggle
     ====================================== */

  /* ===================================
     Start Instagram Section on Blog Page
     ====================================== */
  if($('#instafeed1').length > 0 )
  {
    var userFeed = new Instafeed({
      target:'instafeed1',
      get: 'user',
      userId: $('#userid1').val(),
      accessToken: $('#access1').val(),
      resolution:'standard_resolution',
      template: '<div><a href="{{link}}" target="_blank"><img src="{{image}}"/></a></div>',
      limit: 6
    });
    userFeed.run();
  } 
  /* ===================================
     End Instagram Section on Blog Page
     ====================================== */

  /* ===================================
     Start Search Box
     ====================================== */
  $(".search_icon a").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.search_form').toggle();
    $('.user_account').hide();
  });

  $('.search_form,.user_account').on("click", function (e) {
    e.stopPropagation();
  });

  $(document).on("click", function () {
    $('.search_form,.user_account').hide();
  });


  $('.user_logout').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    $('.user_account').toggle();
    $('.search_form').hide();
  });
  /* ===================================
     End Search Box
     ====================================== */

});
/* ===================================
     End Document.Ready
     ====================================== */

/* ===================================
     Start Window.Load
     ====================================== */

$(window).load(function() {      
  //equalheight('.featured_img_content');
});

/* ===================================
     End Window.Load
     ====================================== */


/* ===================================
     Start Curranct Convert
     ====================================== */
function convertCurrency(price)
{
  var moneyformat = Currency.money_format[Currency.currentCurrency];
  return Currency.formatMoney(Currency.convert(price, shopCurrency, Currency.currentCurrency), moneyformat);
}

function isValid(str) {
  return !/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?\ ]/g.test(str);
}

/* ===================================
     End Curranct Convert
     ====================================== */

/* ===================================
     Start Equal Height Function
     ====================================== */
equalheight = function(container){
  var currentTallest = 0,
      currentRowStart = 0,
      rowDivs = new Array(),
      $el,
      topPosition = 0;
  $(container).each(function() {

    $el = $(this);
    $($el).height('auto')
    topPostion = $el.position().top;

    if (currentRowStart != topPostion) {
      for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
      rowDivs.length = 0; // empty the array
      currentRowStart = topPostion;
      currentTallest = $el.height();
      rowDivs.push($el);
    } else {
      rowDivs.push($el);
      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
    }
    for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
      rowDivs[currentDiv].height(currentTallest);
    }
  });
}

/* ===================================
     End Equal Height Function
     ====================================== */

/* ===================================
     START Instagram slider
     ====================================== */
if($('#instafeed').length > 0 )
{
  var userFeed = new Instafeed({
    target: 'instafeed',
    get: 'user',
    userId: $('#userid').val(),
    accessToken: $('#access').val(),
    resolution: 'thumbnail',
    template: '<a href="{{link}}" target="_blank"><img src="{{image}}"/></a>',
    after: function () {      
      var x = $('.instagram_slider').children();
      var i = 0;
      for (i = 0; i < x.length; i += 2) {
        $('.owl-item:empty').remove();
        x.slice(i, i + 2).wrapAll('<div></div>');
        instaslider('#instafeed');
        $('.owl-next').on("click", function () {
          $('.instagram_slider .owl-prev').show();
          var active = $('.instagram_slider .active').length;
          if (active > 1) {
            $('.instagram_slider .owl-next').hide();
          }
        });
        $('.owl-prev').on("click", function () {
          $('.instagram_slider .owl-next').show();
          if ($(this).hasClass('disabled') == 1) {
            $('.instagram_slider .owl-prev').hide();
          }
        });

      }
    }
  });
  userFeed.run();
}
function instaslider(c) {
  $(c).owlCarousel({
    margin: 2,
    items: 4,
    nav: true,
    dots: false
  });
}

/* ===================================
     End Instagram slider
     ====================================== */


/* ===================================
     START Product Detail Slider
     ====================================== */
var sync1 = $("#sync1");
var sync2 = $("#sync2");
var syncedSecondary = true;
var slidesPerPage = 4;

sync1.owlCarousel({
  items: 1,
  dots: false
}).on('changed.owl.carousel', syncPosition);
sync2.on('initialized.owl.carousel', function () {
  sync2.find(".owl-item").eq(0).addClass("current");
})
.owlCarousel({
  items: slidesPerPage,
  dots: false,
  margin: 12,
  nav: true,
  smartSpeed: 200,
  slideSpeed: 500,
  mouseDrag: false,
  touchDrag: false,
  slideBy: slidesPerPage,
  responsiveRefreshRate: 100
}).on('changed.owl.carousel', syncPosition2);

function syncPosition(el) {
  return;
  
  var count = el.item.count - 1;
  var current = Math.round(el.item.index - (el.item.count / 2) - .5);
  if (current < 0) {
    current = count;
  }
  if (current > count) {
    current = 0;
  }
  sync2
  .find(".owl-item")
  .removeClass("current")
  .eq(current)
  .addClass("current");
  var onscreen = sync2.find('.owl-item.active').length - 1;
  var start = sync2.find('.owl-item.active').first().index();
  var end = sync2.find('.owl-item.active').last().index();

  if (current > end) {
    sync2.data('owl.carousel').to(current, 100, true);
  }
  if (current < start) {
    sync2.data('owl.carousel').to(current - onscreen, 100, true);
  }
}
function syncPosition2(el) {
  return;
  if (syncedSecondary) {
    var number = el.item.index;
    sync1.data('owl.carousel').to(number, 100, true);
  }
}
sync2.on("click", ".owl-item", function (e) {
  e.preventDefault();
  var number = $(this).index();
  sync1.data('owl.carousel').to(number, 300, true);
});
/* ===================================
     END Product Detail Slider
     ====================================== */

/* ===================================
     Start Header Dropdown
     ====================================== */
$(".select_language a").on("click", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('.select-language').toggle();
  $('ul.setting-currency').css('display','none');
});
$(".currencies_select a").on("click", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('.setting-currency').toggle();
  $('ul.select-language').css('display','none');
});
/* ===================================
     End Header Dropdown
     ====================================== */

/* ===================================
     START Back to top
     ====================================== */
if ($('#back-to-top').length) {
  var scrollTrigger = 100, // px
      backToTop = function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > scrollTrigger) {
          $('#back-to-top').addClass('show');
        } else {
          $('#back-to-top').removeClass('show');
        }
      };
  backToTop();
  $(window).on("scroll", function () {
    backToTop();
  });
  $('#back-to-top').on("click", function (e) {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 0
    }, 700);
  });
}
/* ===================================
     END Back to top
     ====================================== */

/* ===================================
     START Block
     ====================================== */
if ($(window).width() <= 1024) {
  $(document).on("click", function (e) {
    var target = e.target;
    if (!$(target).is('.blocks'))
      $(".blocks").removeClass("block_hover");
  });
  $('.blocks').on("click", function (e) {
    e.stopPropagation();
    $('.blocks').removeClass("block_hover");
    $(this).addClass('block_hover');
  });
}
/* ===================================
     END Block
     ====================================== */
/* ===================================
     START Collection Filter
     ====================================== */
$(document).on('click', '.filter_menu a', function () {
  $('.collection_sidebar').animate({left: '0'});
  $('body').addClass('collection-filter');
});
$('.filter_colse,.filter-overlay').on("click", function () {
  var collection_sidebar = $('.collection_sidebar').outerWidth();
  $('.collection_sidebar').animate({left: -collection_sidebar});
  $('body').removeClass('collection-filter');
});
// Filter Toggle 
$(document).on("click", ".category_close_icon", function (a) {
  $(this).parent('.category_list_title').siblings('.layer-filter').slideToggle();
  $(this).parent('.category_list_title').toggleClass('open-filter');
});
/* ===================================
     END Collection Filter
     ====================================== */

/* ===================================
     START mCustomScrollbar
     ====================================== */
$('.mCustomScrollbar').mCustomScrollbar();
/* ===================================
     END mCustomScrollbar
     ====================================== */

/* ===================================
     START Collection Sidebar  Height
     ====================================== */
var s_title = $('.sidebar_title').outerHeight();
var s_height = $(window).height();
var s_total = s_height - s_title;
$('.filter_content').css('height', s_total - 60);
/* ===================================
     END Collection Sidebar  Height
     ====================================== */

$('header .navbar .navbar-nav .nav-item .dropdown-menu li ul li').hover(function(){ 
  var img_src = $(this).data('img');
  var data_title = $(this).data('title');
  $(".drop_img").attr("src", img_src);
  $(".drop_title").text(data_title);
});

/* ===================================
     START Banner slider
     ====================================== */
$(".banner_slider").owlCarousel({
  nav: false,
  dots: true,
  slideSpeed: 300,
  paginationSpeed: 400,
  singleItem: true,
  items: 1
});
/* ===================================
     END Banner slider
     ====================================== */

window.onresize = function () {
  /* ===================================
         START  Window Height 
         ====================================== */
  var window_height = $(window).height();
  var headeheight = $('header').outerHeight();
  var footeheight = $('footer').height();
  var sub_total = headeheight + footeheight;
  var total_height = window_height - sub_total;
  $('.main_section').css('min-height', total_height);
  /* ===================================
         START  Window Height 
         ====================================== */
  /* ===================================
         START  Collection Sidebar  Height
         ====================================== */
  var s_title = $('.sidebar_title').outerHeight();
  var s_height = $(window).height();
  var s_total = s_height - s_title;
  $('.filter_content').css('height', s_total - 60);
  if ($(window).width() <= 768) {
    $('.comming_soon_section').css('height', $(window).height());
  }
  /* ===================================
         END  Collection Sidebar  Height
         ====================================== */
}